import { render, staticRenderFns } from "./Ic4gGmlcView.vue?vue&type=template&id=78648fe6&scoped=true"
import script from "./Ic4gGmlcView.vue?vue&type=script&setup=true&lang=js"
export * from "./Ic4gGmlcView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78648fe6",
  null
  
)

export default component.exports